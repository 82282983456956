import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, pixels, radius, shadow, typography } from '../theme';

const Wrapper = styled.div`
  background-color: ${color.white};
  padding: 1.5rem;
  border-left: ${pixels.four} solid ${color.grey[40]};
  border-radius: ${radius.sm};
  box-shadow: ${shadow.box.card};
  font-size: ${typography.size.f6};

    @media ${breakpoint.md} {
      font-size: ${typography.size.f6};
    }

  p {
    color: ${color.grey[70]};
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
    line-height: 1.25;
    border-bottom: ${pixels.two} solid ${color.brand[30]};

    :hover {
      color: ${color.brand[40]};
    }
  }

  ul {
    list-style: inside;
    color: ${color.grey[70]};
  }

  @media ${breakpoint.md} {
    padding: 2rem;
  }
`;

const Question = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: ${typography.size.f5};

  @media ${breakpoint.md} {
    font-size: ${typography.size.f5};
    letter-spacing: ${typography.spacing.tight}em;
  }
`;

export const FaqCard = ({ question, children }) => {
  return (
    <Wrapper itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <Question itemProp="name">{question}</Question>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">
          {children}
        </div>
      </div>
    </Wrapper>
  );
};
