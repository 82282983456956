import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { ContactLink } from '../components/ContactLink';
import SEO from '../components/core/Seo';
import { FaqCard } from '../components/FaqCard';
import Layout from '../layouts/Layout';
import InfoPage from '../templates/InfoPage';

const Section = styled.div`
  margin-bottom: 4rem;

  > div {
    margin-bottom: 2rem;
  }
`;

const Faqs = () => {
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <div itemScope itemType="https://schema.org/FAQPage">
        <InfoPage span="FAQs" title="Answers to your questions">
          <Section>
            <FaqCard question="Where are the questions?">
              <p>Hang tight, they're coming soon!</p>
            </FaqCard>

          </Section>

          <ContactLink />
        </InfoPage>
      </div>
    </Layout >
  );
};

export default Faqs;
